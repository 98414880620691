<template>
  <div class="container-lg" >
    <div class="row m-0">
      <div class="col-12 p-0">
        <PageTitle :title="$route.name" />
        <div class="row mx-0 mt-5">
          <div class="col-md-6 col-xl-4 p-3" v-for="item in news" :key="item.id">
            <NewsCard :newsItem="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    NewsCard: defineAsyncComponent(() => import('./views/NewsCard.vue'))
  },
  name: 'News',
  computed: {
    ...mapGetters(['news'])
  }
}
</script>

<style scoped>
</style>
