<template>
  <div class="container-lg" >
    <div class="row m-0">
      <div class="col-12 p-0">
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-md-8 col-lg-7 col-xl-6 text-center p-0 my-4 page_heading">
                Empowering lives in over<br/>75 countries worldwide...
              </div>
            </div>
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-sm-9 col-md-7 col-lg-6 col-xl-5 col-xxl-4 text-center p-0 my-4 page_heading">
                <YouTubeVideo link="LupIz4CZ3Ls" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 p-0 d-none d-md-block" v-if="!isApp">
        <SectionTitle title="FEATURED PROJECTS" />
      </div>
      <div class="col-12 p-0 my-5 d-none d-md-block" v-if="!isApp">
        <div class="row mx-0" v-if="isUK">
          <div class="col-6 mb-3 px-2" v-for="project in contentProjects.slice(0, 2)" :key="project.id">
            <FeaturedProject :project="project" />
          </div>
          <div class="col-4 mb-3 px-2" v-for="project in contentProjects.slice(2, 5)" :key="project.id">
            <FeaturedProject :project="project" size="small" />
          </div>
        </div>
        <div class="row mx-0" v-else>
          <div class="col-6 mb-3 px-2">
            <FeaturedProjectSA @click="filterSA" />
          </div>
          <div class="col-6 mb-3 px-2" v-for="project in contentProjects.slice(0, 1)" :key="project.id">
            <FeaturedProject :project="project" />
          </div>
          <div class="col-4 mb-3 px-2" v-for="project in contentProjects.slice(1, 4)" :key="project.id">
            <FeaturedProject :project="project" size="small" />
          </div>
        </div>
      </div>
      <div class="col-12 ">
        <SectionTitle title="View all our recent work" />
      </div>
      <div class="col-12">
        <div class="row align-items-center justify-content-center">
          <div class="col-12 col-md-12 p-0 my-5 text-center">
            <div class="row m-0 justify-content-center">
              <div class="col-7 col-md-5">
                <Search type="text" color="outline" size="big" placeholder="Search" align="left" id="search" v-model="projectSearch"/>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-12 p-0 mb-5">
            <div class="row m-0 justify-content-center">
              <div class="col-auto my-3">
                <ProjectFilter :filteredByCountry="country" @updateYear="updateYear" @updateCountry="updateCountry" @updateCategory="updateCategory" @updateAppealType="updateAppealType" :filteredProjects="filteredProjects" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 p-0">
        <div class="row m-0" ref="theProjects">
          <div class="col-lg-6 my-3" v-for="project in filteredProjects.slice(0, showNumber)" :key="project.id">
            <ProjectCard :project="project"/>
          </div>
        </div>
        <div class="row mx-0 my-5 justify-content-center">
          <div class="col-auto">
            <Button color="green" btnText="Load more" icon="arrow" @buttonClicked="showMore" v-if="filteredProjects.length > showNumber">
              <ios-arrow-round-down-icon class="inline-icon"/>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    YouTubeVideo: defineAsyncComponent(() => import('@/components/YouTubeVideo.vue')),
    Search: defineAsyncComponent(() => import('@/components/Search.vue')),
    SectionTitle: defineAsyncComponent(() => import('@/components/SectionTitle.vue')),
    FeaturedProject: defineAsyncComponent(() => import('@/views/FeaturedProject.vue')),
    FeaturedProjectSA: defineAsyncComponent(() => import('@/views/FeaturedProjectSA.vue')),
    ProjectCard: defineAsyncComponent(() => import('@/views/ProjectCard.vue')),
    ProjectFilter: defineAsyncComponent(() => import('@/views/ProjectFilter.vue')),
    'ios-arrow-round-down-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-arrow-round-down.vue'))
  },
  name: 'Our Work',
  data () {
    return {
      projectType: null,
      year: null,
      appealType: null,
      country: null,
      projectSearch: '',
      showNumber: 10
    }
  },
  computed: {
    ...mapGetters([
      'contentProjects', 'isApp'
    ]),
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    filteredProjects () {
      const search = this.projectSearch.toLowerCase().trim()
      return this.contentProjects.filter(project => {
        if (this.projectType && !this.projectType.includes(project.projectType.id)) {
          return false
        }
        if (this.country && !this.country.includes(project.location.country.id)) {
          return false
        }
        const dateYear = DateTime.fromMillis(project.uiDate).toFormat('yyyy')
        if (this.year && !this.year.includes(dateYear)) {
          return false
        }
        if (this.appealType && this.appealType.length > 0) {
          let typeRet = false
          this.appealType.forEach(type => {
            if (project[type]) {
              typeRet = true
            }
          })
          if (typeRet === false) {
            return false
          }
        }
        if (search && project.name.toLowerCase().indexOf(search) <= -1) {
          return false
        }
        return true
      })
    }
  },
  methods: {
    updateYear (val) {
      if (val === null) {
        this.year = ''
      } else {
        this.year = val
      }
    },
    updateCountry (val) {
      if (val === null) {
        this.country = null
      } else {
        this.country = val
      }
    },
    updateCategory (val) {
      if (val === null) {
        this.projectType = null
      } else {
        this.projectType = val
      }
    },
    updateAppealType (val) {
      if (val === null) {
        this.appealType = null
      } else {
        this.appealType = val
      }
    },
    showMore () {
      this.showNumber += 10
    },
    filterSA () {
      this.updateCountry([21])
      this.$nextTick(() => {
        var element = this.$refs.theProjects
        var top = element.offsetTop
        window.scrollTo(0, top)
      })
    }
  }
}
</script>

<style scoped>
.border_right {
  border-right: 1px solid var(--gold-color);
}
@media (min-width: 992px) {
  .border_right {
    border-right: none;
  }
}
</style>
