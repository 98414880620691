<template>
  <div class="container-lg" >
    <div class="row m-0">
      <div class="col-12 p-0">
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-md-8 col-lg-7 col-xl-6 text-center p-0 my-4 page_heading">
                {{newsItem.title}}
              </div>
              <div class="col-12 text-center mb-3 p-0 gold_heading" v-if="newsItem.lastModifiedDate">
                {{getDate}}
              </div>
            </div>
            <div class="row mx-0 justify-content-center height">
              <div class="col-11 col-sm-9 col-md-8 col-lg-7 col-xl-6 col-xxl-6 h-100 p-0 relative box_shadow">
                <div class="imageSlider-item height">
                  <div class="h-100" ref="theImage">
                    <Image :imageName="newsItem.imageUrl" :width="theImageWidth" :height="theImageHeight" theClass="d-block w-100 slider_img" :alt="newsItem.name" />
                    <!-- <img :src="url + '/static/uploads/images/' + newsItem.imageUrl" class="d-block w-100 slider_img" :alt="newsItem.name" /> -->
                    <div class="overlay">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col-12 my-2">
            <SectionTitle title="Details" />
            <div class="row mx-0 mt-5 justify-content-center">
              <div class="col-md-10 p-0" v-html="newsItem.content">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    Image: defineAsyncComponent(() => import('@/components/Image.vue')),
    SectionTitle: defineAsyncComponent(() => import('@/components/SectionTitle.vue'))
  },
  name: 'News Article',
  data () {
    return {
      url: process.env.VUE_APP_URL_BASE,
      theImageWidth: 0,
      theImageHeight: 0
    }
  },
  async mounted () {
    await this.getDimensions()
  },
  methods: {
    getDimensions () {
      this.theImageWidth = this.$refs.theImage.clientWidth
      this.theImageHeight = this.$refs.theImage.clientHeight
    }
  },
  computed: {
    ...mapGetters(['news']),
    newsItem () {
      const id = parseInt(this.$route.params.id)
      if (this.news.length > 0) {
        const filterArr = this.news.filter((article) => {
          return article.id === id
        })
        if (filterArr.length > 0) {
          return filterArr[0]
        }
      }
      return {}
    },
    getDate () {
      const date = DateTime.fromMillis(this.newsItem.lastModifiedDate)
      return date.toLocaleString(DateTime.DATE_FULL)
    }
  }
}
</script>

<style scoped>
.gold_heading {
  color: var( --gold-color );
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 1px;
  text-shadow: 5px 5px 10px rgb(0 0 0 / 67%);
}
.imageSlider-item {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
  .fill {
    max-height: 40vh;
  }
  .imageSlider-item {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
  .box_shadow {
    box-shadow: 0px 15px 24px 0px rgb(0 0 0 / 21%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 15px 15px 15px 15px;
    padding: 45px 45px 45px 45px;
    border-radius: 15px;
  }
  .height {
    height: 177px;
  }
  .slider_img  {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 22px;
  }
  .page_title {
    font-size: 14px;
    color: var(--green-color);
    font-family: "quicksand_bold", Sans-serif;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 6.8px;
  }
  .page_title.white {
    color: #fff;
  }
  .page_title_underline {
    border-bottom: 2px solid var(--gold-color);
    width: 131px;
  }
  .price {
    background-color: var(--green-color-dark);
    padding: 6px 15px;
    color: var(--gold-color);
    border-radius: 37px;
    width: auto;
    font-size: 12px;
    bottom: 25px;
    text-transform: lowercase;
  }
  .border_end {
    border-right: none;
  }
  @media (min-width: 992px) {
    .page_title {
      font-size: 21px;
    }
    .height {
      height: 325px;
    }
    .slider_img  {
      border-radius: 15px;
    }
    .page_title_underline {
      width: 200px;
    }
    .price {
      padding: 7px 17px;
      font-size: 23px;
      bottom: 45px;
    }
    .border_end {
      border-right: 1px solid rgb(204, 204, 204);
    }
  }
</style>
