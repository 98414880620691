<template>
  <div class="container-lg" >
    <div class="row m-0">
      <div class="col-12 p-0">
        <PageTitle :title="$route.name" />
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-md-8 col-lg-7 col-xl-6 text-center p-0 my-4 page_heading">
                Browse through all the ways you can make a difference.
              </div>
            </div>
            <AppealSlider :posters="currentPosters" />
          </div>
        </div>
      </div>
      <div class="col-12 p-0" ref="theAppeals">
        <AppealSection title="View all our appeals" btnText="More Info"  :pageId="pageId" v-if="pageId === 0 || pageId === 1" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    AppealSection: defineAsyncComponent(() => import('./views/AppealSection.vue')),
    AppealSlider: defineAsyncComponent(() => import('./components/AppealSlider.vue'))
  },
  name: 'Appeals',
  data () {
    return {
      pageId: 0
    }
  },
  async mounted () {
    this.pageId = await parseInt(this.$route.params.id)
    if (this.pageId === 1) {
      setTimeout(() => {
        this.goToAppeals()
      }, 700)
    }
  },
  computed: {
    ...mapGetters([
      'currentPosters'
    ])
  },
  methods: {
    goToAppeals () {
      this.$nextTick(() => {
        var element = this.$refs.theAppeals
        var top = element.offsetTop
        setTimeout(() => {
          window.scrollTo(0, top)
        }, 700)
      })
    }
  }
}
</script>
