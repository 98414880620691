<template>
  <div class="container-lg normal-text-dark" >
    <div class="row m-0 align-items-center justify-content-center" v-if="mergedProject">
      <div class="col-12 p-0">
        <PageTitle :title="pageTitle" />
        <div class="row mx-0 justify-content-center page_top">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-11 col-md-8 col-lg-7 col-xl-6 text-center p-0 my-4 page_heading">
                <span v-if="mergedProject !== project">{{mergedProject.description}}</span>
                <span v-else>{{project.name}}</span>
              </div>
            </div>
            <ProjectIndividualImage :project="mergedProject" :currency="defaultCurrency" />
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <ProjectDescriptionSection :description="project.description" />
        <ProjectDonateSection :project="project" :mergedProject="mergedProject" :currency="defaultCurrency" />
      </div>
    </div>
  </div>
</template>

<script>
// import { DateTime } from 'luxon'
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    ProjectIndividualImage: defineAsyncComponent(() => import('./views/ProjectIndividualImage.vue')),
    ProjectDescriptionSection: defineAsyncComponent(() => import('./views/ProjectDescriptionSection.vue')),
    ProjectDonateSection: defineAsyncComponent(() => import('./views/ProjectDonateSection.vue'))
  },
  name: 'Project Individual',
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL
    }
  },
  computed: {
    ...mapGetters(['adminProjects', 'contentProjects']),
    pageTitle () {
      if (this.project && this.project.projectType && this.project.projectType.name) {
        return this.project.projectType.name
      } else {
        return ''
      }
    },
    project () {
      const id = parseInt(this.$route.params.id)
      if (this.contentProjects.length > 0) {
        const filterArr = this.contentProjects.filter((project) => {
          return project.id === id
        })
        if (filterArr.length > 0) {
          return filterArr[0]
        }
      }
      return {}
    },
    mergedProject () {
      if (this.project && this.project.adminProjectId) {
        const adminProject = this.adminProjects.find(p1 => p1.projectID === this.project.adminProjectId)
        const mergedProject = { ...this.project, ...adminProject }
        return mergedProject
      } else {
        return this.project
      }
    }
  }
}
</script>
